import React from 'react';

const IconLogo = () => (
  <svg id="logo" version="1.0" xmlns="http://www.w3.org/2000/svg" width="640px" height="646px" viewBox="0 0 6400 6460" preserveAspectRatio="xMidYMid meet">
    <g id="B" fill="#ccd6f6" stroke="none">
      <path d="M2805 6449 c-343 -28 -752 -135 -1030 -269 -164 -80 -205 -127 -205 -237 0 -103 -24 -77 921 -1028 484 -488 619 -631 619 -655 0 -10 -15 -13 -635 -109 -281 -44 -739 -117 -1020 -161 -585 -93 -968 -150 -974 -145 -2 3 11 61 28 131 103 410 253 730 482 1022 l64 82 165 -163 c91 -89 195 -191 232 -226 l67 -65 83 13 c313 46 428 70 428 87 0 24 -463 501 -708 730 -142 133 -207 174 -272 174 -86 0 -155 -49 -289 -204 -366 -427 -613 -940 -717 -1491 -21 -111 -27 -172 -28 -295 -1 -146 0 -157 22 -193 13 -20 45 -49 70 -65 42 -24 56 -27 127 -25 44 1 407 53 810 117 402 63 1090 172 1530 241 903 142 1115 179 1165 203 47 23 90 104 97 180 l5 57 -67 78 c-92 107 -359 381 -1023 1049 -310 311 -561 571 -558 577 11 17 191 70 346 101 213 42 351 53 589 47 271 -7 438 -34 725 -117 76 -22 146 -40 156 -40 20 0 78 110 135 259 41 106 43 125 12 146 -96 62 -564 166 -877 195 -130 11 -327 11 -475 -1z" />
      <path d="M4814 5780 c-23 -12 -49 -29 -57 -38 -37 -42 -260 -599 -770 -1923 -403 -1044 -533 -1390 -542 -1442 -14 -73 67 -190 152 -218 59 -19 238 23 1318 311 912 243 973 259 979 252 12 -11 6 -52 -24 -166 -64 -248 -202 -558 -338 -762 -112 -166 -184 -253 -386 -464 -97 -102 -176 -189 -176 -195 0 -9 144 -194 221 -283 20 -23 44 -42 54 -42 29 0 420 400 517 530 277 368 492 839 577 1260 25 123 40 245 50 400 l8 125 -46 49 c-61 64 -118 90 -180 81 -88 -13 -544 -131 -1746 -454 -143 -38 -288 -76 -322 -84 l-62 -14 34 91 c102 282 480 1257 730 1886 205 514 207 520 219 520 15 0 214 -200 313 -315 128 -148 261 -351 343 -520 89 -184 115 -256 98 -266 -7 -4 -80 -26 -163 -49 -267 -73 -420 -121 -437 -137 -31 -28 -199 -466 -184 -480 18 -18 1161 292 1220 331 14 9 39 40 56 69 40 70 39 123 -5 276 -163 568 -592 1175 -1093 1548 -178 133 -275 166 -358 123z" />
      <path d="M2044 3389 c-35 -10 -90 -66 -105 -107 -14 -38 -256 -925 -414 -1522 -183 -688 -181 -683 -204 -670 -33 17 -132 112 -218 209 -169 189 -279 354 -402 600 -118 235 -191 438 -235 652 -14 67 -29 126 -34 131 -9 9 -210 -15 -342 -41 l-85 -16 3 -50 c4 -64 79 -356 130 -505 100 -293 225 -538 401 -780 111 -154 227 -285 412 -465 290 -283 443 -377 565 -346 58 15 119 55 143 95 11 17 63 195 116 396 191 722 456 1685 466 1695 17 16 140 -128 650 -760 174 -214 436 -538 583 -720 276 -340 389 -486 422 -543 18 -32 18 -34 1 -47 -21 -15 -188 -65 -347 -102 l-115 -28 -395 0 c-217 0 -407 3 -422 8 -44 12 -41 30 64 418 l61 227 -131 158 c-161 196 -198 237 -211 229 -31 -19 -302 -1034 -316 -1181 -7 -84 9 -122 73 -171 124 -94 547 -162 937 -150 370 11 665 67 1020 194 210 75 320 138 366 212 38 60 41 156 7 219 -28 50 8 5 -652 822 -295 366 -720 892 -944 1170 -559 694 -613 757 -659 769 -45 13 -146 13 -189 0z" />
    </g>

  </svg>
);

export default IconLogo;
